import {
	colorPalette,
	fontSize,
	fontWeight,
	mainLinks,
	supporter,
	fontFamily,
} from '../../config';
import {
	participateLogoImg,
	recogLogoImg,
	infoLogoImg,
	recordLogoImg,
	notifyLogoImg,
	support1,
	support2,
	cbsImg,
	gyeongSangImg,
	jinjuImg,
	courseMainImg,
} from '../../config/images';

const dateTextProps = {
	title: {
		fontSize: {
			desktop: fontSize.xl5,
			tablet: '30px',
			mobile: '24px',
		},
		color: colorPalette.white,
		fontWeight: fontWeight.bold,
		shadow: `0 0 10px rgba(0, 0, 0, 0.7);`,
	},
	unit: {
		fontSize: {
			desktop: fontSize.xl2,
			tablet: '22px',
			mobile: '20px',
		},
		color: colorPalette.white,
		fontWeight: fontWeight.bold,
		opacity: 0.64,
		shadow: `0 0 10px rgba(0, 0, 0, 0.7);`,
	},
	value: {
		fontSize: {
			desktop: fontSize.xl7,
			tablet: '44px',
			mobile: '38px',
		},
		color: colorPalette.white,
		fontWeight: fontWeight.bold,
		shadow: `0 0 10px rgba(0, 0, 0, 0.7);`,
	},
};

export const firstProps = {
	minititle: {
		type: 'p',
		children: `제18회`,
		fontSize: {
			desktop: '24px',
			tablet: '24px',
			mobile: '15px',
		},
		fontFamily: 'Pretendard',
		color: '#25a3d2',
		fontWeight: fontWeight.bold,
		marginBotton: '12px',
	},
	title: {
		type: 'p',
		children: `여수해양마라톤`,
		fontSize: {
			//desktop: fontSize.xl10,
			desktop: '71px',
			tablet: '71px',
			mobile: '36px',
		},
		fontFamily: 'Gmarket',
		color: colorPalette.yeosumain,
		fontWeight: fontWeight.extraBold,
		marginBotton: '13px',
	},
	content: {
		type: 'p',
		children: `2024.01.07 (일)`,
		fontSize: {
			desktop: fontSize.xl5,
			tablet: '35px',
			mobile: '18px',
		},
		color: colorPalette.yeosumain,
		fontWeight: fontWeight.bold,
		fontFamily: 'Gmarket',
	},
	button: {
		text: {
			children: '신청하기',
			fontSize: {
				desktop: '24px',
				tablet: '24px',
				mobile: '16px',
			},
			lineHeight: 2.4,
			color: colorPalette.white,
			fontWeight: fontWeight.medium,
			fontFamily: 'Pretendard',
		},
		onClick: () =>
			(window.location.href = `${window.location.origin}/participation/person`),
		isRounded: true,
		border: `none`,
		bgColor: colorPalette.yeosumain,
	},
	date: {
		title: {...dateTextProps.title, children: '행사 시작까지'},
		day: {...dateTextProps.unit, children: 'DAY'},
		dayValue: {...dateTextProps.value, children: '14'},
		hour: {...dateTextProps.unit, children: 'HOUR'},
		hourValue: {...dateTextProps.value, children: '8'},
		minute: {...dateTextProps.unit, children: 'MINUTES'},
		minuteValue: {...dateTextProps.value, children: '25'},
		second: {...dateTextProps.unit, children: 'SECONDS'},
		secondValue: {...dateTextProps.value, children: '32'},
	},
};

const secondTextProps = {
	title: {
		fontSize: {
			desktop: '26px',
			tablet: '26px',
			mobile: '26px',
		},
		color: colorPalette.primary,
		fontWeight: fontWeight.medium,
		fontFamily: 'Gmarket',
	},
	content: {
		fontSize: {
			desktop: '16px',
			tablet: '16px',
			mobile: '16px',
		},
		color: colorPalette.noneBorder,
		fontWeight: fontWeight.base,
		fontFamily: 'Gmarket',
	},
	support: {
		fontSize: {
			desktop: fontSize.xl2,
			tablet: '22px',
			mobile: '15px',
		},
		color: colorPalette.base,
		fontWeight: fontWeight.semiBold,
	},
};

export const secondProps = {
	info: {
		title: {
			...secondTextProps.title,
			children: '마라톤 알아보기',
		},
		content: {
			...secondTextProps.content,
			children: `마라톤에 도움이 되는 바로가기\n정보를 안내합니다.`,
		},
		links: mainLinks,
	},
	supports: supporter,
};

export const thirdTextProps = {
	title: {
		fontSize: {
			desktop: fontSize.xl3,
			tablet: '36px',
			mobile: '26px',
		},
		fontWeight: fontWeight.medium,
		color: colorPalette.white,
		fontFamily: 'Gmarket',
	},
	description: {
		fontSize: {
			desktop: '16px',
			tablet: '16px',
			mobile: '16px',
		},
		fontWeight: fontWeight.medium,
		color: colorPalette.white,
	},
	item: {
		title: {
			fontSize: {
				desktop: '20px',
				tablet: '20px',
				mobile: '18px',
			},
			color: colorPalette.realBlack,
			fontWeight: fontWeight.bold,
			lineHeight: '30px',
		},
		content: {
			fontSize: {
				desktop: '20px',
				tablet: '20px',
				mobile: '16px',
			},
			color: colorPalette.realBlack,
			fontWeight: fontWeight.normal,
			lineHeight: '30px',
		},
		smalContent: {
			fontSize: {
				desktop: fontSize.base,
				tablet: '16px',
				mobile: '16px',
			},
			color: colorPalette.tertiary,
			fontWeight: fontWeight.medium,
		},
		accentContent: {
			fontSize: {
				desktop: '20px',
				tablet: '20px',
				mobile: '16px',
			},
			color: colorPalette.tertiary,
			fontWeight: fontWeight.normal,
			lineHeight: '30px',
		},
	},
};

export const thirdProps = {
	title: {
		textAlign: 'left',
		bgColor: colorPalette.white,
		height: '3px',
		text: {...thirdTextProps.title, children: '대회요강', type: 'p'},
		description: {
			...thirdTextProps.description,
			children: '반드시 참가 유의사항을 읽고 신청해주세요.',
		},
	},
	first: [
		{
			title: {...thirdTextProps.item.title, children: '대회명'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `제 18회 여수해양마라톤대회`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '대회일시'},
			contents: [
				{
					...thirdTextProps.item.title,
					children: `2024년 1월 7일 (일)`,
				},
				{
					...thirdTextProps.item.content,
					children: `09:30 풀코스 출발\n09:40 하프코스 출발\n09:50 10km코스 출발\n09:55 5km코스 출발\n`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '집결시간'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `오전 9시`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '장소'},
			contents: [
				{...thirdTextProps.item.content, children: `여수진남경기장`},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '참가구분'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `풀코스/하프코스/10km/5km`,
				},
				{
					...thirdTextProps.item.content,
					children: `4가지 코스 중 선택 가능`,
				},
			],
		},
	],
	second: [
		{
			title: {...thirdTextProps.item.title, children: '참가자격'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `나이제한 없으며, 신체 건강한 남 / 녀`,
				},
				{
					...thirdTextProps.item.smalContent,
					children: `대한육상경기연맹 등록선수 및 선수등록 말소 후\n2년 경과되지 않은 자는 시상에서 제외됩니다.`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '접수기간'},
			contents: [
				{
					...thirdTextProps.item.title,
					children: `2023년 12월 24일(일) 자정까지`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '접수방법'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `인터넷으로만 접수`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '모집인원'},
			contents: [
				{
					...thirdTextProps.item.accentContent,
					children: `종목별 1500 명 이내 ( 선착순 )`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '참가비'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `풀코스 - 30,000원\n하프코스 - 30,000원\n10km코스 - 20,000원\n5km코스 - 10,000원`,
				},
			],
		},
	],
};

const fourthTextProps = {
	title: {
		fontSize: {
			desktop: fontSize.xl3,
			tablet: '36px',
			mobile: '24px',
		},
		fontWeight: fontWeight.medium,
		color: colorPalette.black,
		fontFamily: 'Gmarket',
	},
	description: {
		fontSize: {
			desktop: '16px',
			tablet: '16px',
			mobile: ' 16px',
		},
		fontWeight: fontWeight.medium,
		color: colorPalette.engTitle,
	},
};

export const fourthProps = {
	title: {
		textAlign: 'left',
		bgColor: colorPalette.primary,
		height: '5px',
		text: {...fourthTextProps.title, children: '대회코스', type: 'p'},
		description: {
			...fourthTextProps.description,
			children: '대회 구간을 참고하시고 신청 바랍니다',
		},
	},
	course: {
		src: courseMainImg,
		alt: '코스',
		borderRadius: '1.375rem',
	},
};

export const modalProps = {
	isClose: true,
	img: {src: courseMainImg},
};
